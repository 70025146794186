<template>
    <div
        class="full-height"
    >
        <div class="flex-column">
            <div>
                <h6 class="text-left">정산 구분</h6>
                <div class="top-line-identify pa-10 text-left box bg-eee">
                    <button
                        v-for="(item, index) in codes.settlement_agency_type"
                        :key="'settlement_agency_type_' + index"
                        class="pa-5-10 border-right"
                        @click="search.type = item.code; getData()"
                        :class="search.type == item.code ? 'bg-identify' : 'bg-white'"
                    >{{ item.name }}
                    </button>
                </div>
            </div>
            <div>
                <h6 class="text-left">매출 합계</h6>
                <table class="table top-line-identify">
                    <thead>
                    <tr>
                        <th>매출 시작일</th>
                        <th>매출 종료일</th>
                        <th>정산 기준일</th>
                        <th>승인금액</th>
                        <th>정산금액</th>
                        <th>실제수익</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ summary.startDate | transDate }}</td>
                        <td>{{ summary.endDate | transDate }}</td>
                        <td>{{ summary.settlementDate | transDate }}</td>
                        <td class="text-right">{{ summary.approvalAmount | makeComma }}원</td>
                        <td class="text-right">{{ summary.settlementAmount | makeComma }}원</td>
                        <td class="text-right">{{ summary.returnAmount | makeComma }}원</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="mt-10 full-height ">
                <h6 class="justify-space-between">
                    매출 내역

                    <div>
                        <button
                            class="box mr-10 pa-4-10 size-px-12"
                            @click="toExcel"
                        >
                            <v-icon small class="color-green ">mdi mdi-file-excel</v-icon>
                            <span class="vertical-middle">엑셀 다운로드</span></button>
                        <select
                            class="pa-5-10  size-px-12"
                            v-model="search.size"
                            @change="getData()"
                        >
                            <option
                                v-for="cnt in codes.list_cnt"
                                :key="'cnt_' + cnt"
                                :value="cnt"
                            >{{ cnt }} 건씩 보기
                            </option>
                        </select>
                    </div>
                </h6>
                <table
                    v-if="items.length > 0"
                    class="table top-line-identify"
                >
                    <colgroup>
                        <col width="40px"/>
                        <col width="auto"/>
                        <col width="auto"/>
                        <col width="auto"/>
                        <col width="auto"/>

                        <col width="auto"/>
                        <col width="auto"/>
                        <col width="auto"/>
                        <col width="auto"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>NO</th>
                        <th>정산기준일</th>
                        <th>가맹점</th>
                        <th>결제건수</th>
                        <th>승인금액</th>

                        <th>차감건수</th>
                        <th>차감금액</th>
                        <th>수수료(부가세포함)</th>
                        <th>정산금액</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(item, index) in item_list"
                        :key="'item_' + index"
                    >
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.settlementDate | transDate }}</td>
                        <td class="text-left">{{ item.shopName }}</td>
                        <td class="text-right">{{ item.totalCount | makeComma }}건</td>
                        <td class="text-right">{{ item.approvalAmount | makeComma }}원</td>

                        <td class="text-right">{{ item.deductionCount | makeComma }}건</td>
                        <td class="text-right">{{ item.deductionAmount | makeComma }}원</td>
                        <td class="text-right">{{ item.feeAndTaxAmount | makeComma }}원</td>
                        <td class="text-right">{{ item.settlementAmount | makeComma }}원</td>
                    </tr>
                    </tbody>
                </table>

                <div
                    v-else
                    class="full-height flex-column justify-center mt-10 top-line-identify"
                >
                    <div class="text-center">
                        <v-icon
                            class="size-px-48 "
                        >mdi mdi-cloud-off-outline
                        </v-icon>
                        <br/>
                        <br/>
                        <div class="font-weight-bold size-px-24">No Data</div>
                    </div>
                </div>
            </div>
        </div>
        <pagination
            class="mt-auto"
            :options="search"

            @click="getSearch"
        ></pagination>

        <Excel
            v-if="is_excel"
            :excel_data="excel_data"

            @finish="is_excel = !is_excel"
        ></Excel>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Excel from "@/components/Excel";

export default {
    name: 'SettlementMypageDetail'
    , components: {Excel, Pagination}
    , props: ['item', 'idx', 'year']
    , data: function () {
        return {
            items: []
            , search: {
                page: 1
                , size: 10
                , type: ''
            }
            , summary: []
            , is_excel: false
            , excel_data: {
                name: '마이 정산 상세'
                , header: [
                    {key: 0, name: '정산기준일', column: 'settlementDate'}
                    , {key: 0, name: '가맹점', column: 'shopName'}
                    , {key: 0, name: '결제건수', column: 'totalCount'}
                    , {key: 0, name: '승인금액', column: 'approvalAmount'}
                    , {key: 0, name: '차감건수', column: 'deductionCount'}
                    , {key: 0, name: '차감금액', column: 'deductionAmount'}
                    , {key: 0, name: '수수료(부가세포함)', column: 'feeAndTaxAmount'}
                    , {key: 0, name: '정산금액', column: 'settlementAmount'}
                ]
                , content: null
            }
        }
    }
    , computed: {
        item_list: function () {
            return this.items.filter(function (item) {
                return item
            })
        }
    }
    , methods: {
        getData: async function () {
            try {
                this.$layout.onLoading()
                console.log(this.search)
                const data = new URLSearchParams(this.search).toString()

                const result = await this.$Axios({
                    method: 'get'
                    , url: 'calculate/my/detail/' + this.year + '/' + this.item.month + '?' + data
                })

                if (result.success) {
                    this.items = result.data.data
                    this.$set(this.search, 'total_count', result.data.totalCount)
                    // this.summary = result.data.statistics
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }

                const result2 = await this.$Axios({
                    method: 'get'
                    , url: 'calculate/my/detail/statistics/' + this.year + '/' + this.item.month + '?' + data
                })

                if (result2.success) {
                    console.log(result2.data, 'list')
                    this.summary = result2.data
                } else {
                    this.$layout.setNotify( {type: 'error', message: result2.message})
                }

            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }

        , toExcel: async function () {
            try {
                const data = new URLSearchParams(this.search).toString()
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'calculate/my/detail/excel/' + this.year + '/' + this.item.month + '?' + data
                })

                if (result.success) {
                    this.excel_data.content = result.data
                    this.is_excel = true
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , getSearch: function (page) {
            if (page) {
                this.search.page = page
            }

            this.getData()
        }
    }
    , created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.getData()
    }
}
</script>